import { get } from 'lodash'
import React from 'react'
import {
  VictoryArea,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryPolarAxis,
  VictoryScatter
} from 'victory'
import { getScore, HSDScoreConfig, OFFSET_NAME_COMPARE_Y, OFFSET_SORCE_COMPARE_Y, SCORE_X, LABEL_SCORE_ANCHOR, LABEL_TEXT_ANCHOR } from './HSDContainer'

export const HSDSpiderGraph = (props) => {
  const { latestData } = props
  const filteredlatestData = latestData.filter((item) => item.name !== 'Cardiometabolic')
  const data = filteredlatestData.map((domain, index) => {
    const score = get(domain, 'score_manual.value') || get(domain, 'score_auto')
    const config = score && HSDScoreConfig(score)
    return {
      x: index,
      y: score || 0,
      score: score,
      color: config ? config.color : 'rgba(77, 77, 77, 1)',
      text: config ? `${score} │ ${config.text}` : '--'
    }
  })

  return (
    <VictoryChart polar domain={{ y: [0, 100] }} startAngle={90} endAngle={450}>
      <VictoryPolarAxis
        name="graph-score"
        labelPlacement="vertical"
        tickValues={filteredlatestData.map((_, index) => index)}
        tickFormat={(t) => data[t].text}
        tickLabelComponent={
          <VictoryLabel
            dx={(t) => SCORE_X[t]}
            dy={(y) => OFFSET_SORCE_COMPARE_Y[y || 0]}
            textAnchor={(t) => LABEL_SCORE_ANCHOR[t]}
            inline
            style={[
              {
                fill: (t = 0) => {
                  return data[t].color
                },
                fontSize: 10,
                fontFamily: 'Gilroy',
                fontWeight: 600
              }
            ]}
          />
        }
        style={{
          axis: {
            fill: 'rgba(205, 208, 214, 0.2)' // background
          },
          grid: {
            stroke: '#B1B8BD'
          }
        }}
      />
      <VictoryPolarAxis
        name="graph-label"
        tickValues={filteredlatestData.map((_, index) => index)}
        tickFormat={(t) => filteredlatestData[t] ? filteredlatestData[t].name.toUpperCase() : ''}
        tickLabelComponent={
          <VictoryLabel
            dy={(y) => OFFSET_NAME_COMPARE_Y[y || 0]}
            dx={(t) => SCORE_X[t]}
            textAnchor={(t) => LABEL_TEXT_ANCHOR[t]}
          />
        }
        labelPlacement="vertical"
        style={{
          axis: { stroke: 'transparent' },
          grid: { stroke: '#516960' },
          tickLabels: {
            fontSize: 8,
            fill: (t) => 'rgba(76,96,114,.6)'
          }
        }}
      />
      <VictoryPolarAxis
        dependentAxis
        tickFormat={(t) => null}
        style={{
          grid: {
            stroke: (t) => (t === 100 ? '#0D2350' : '#B1B8BD')
          },
          axis: {
            stroke: 'transparent'
          }
        }}
      />
      <VictoryArea
        style={{ data: { fill: 'rgba(180, 232, 195, 0.2)' } }}
        data={data}
      />

      <VictoryLine
        data={data}
        style={{
          data: {
            stroke: '#5EA4BA',
            strokeWidth: 1.5
          }
        }}
      />
      <VictoryScatter
        data={data.filter((i) => i.score)}
        style={{
          data: {
            stroke: (t) => t.color,
            strokeWidth: 2,
            fill: 'white'
          }
        }}
      />
    </VictoryChart>
  )
}
