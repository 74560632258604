import React, { useState, useEffect, useMemo } from 'react'
import { Tabs, Divider, message } from 'antd'
import _ from 'lodash'
import { rangeAreaStyle } from '../../../components/business/dataset/ranges/index.sass'
import HexagonText from '../../UI/HexagonText'
import { About, Lifestyle } from '../../icons'
import { detailColors } from '../../../utils'
import { createLifeSuggestions, getLifeSuggestionsById } from '../../../api'
import { updateLifeSuggestions } from '../../../api'
import './LowLevelMetrics.css'
import ConditionalHideWrap from './ConditionalHidewrap'
import BasicForm from '../../BasicForm'
import { LoadingOutlined } from '@ant-design/icons'
const { TabPane } = Tabs

function MetricRangesArea({
  type,
  value,
  ranges,
  color,
  metric,
  level,
  sectionName
}) {

  const processedRanges = ranges.map(range =>
    range !== null
      ? range
      : { name: 'Undefined', min: undefined, max: undefined, color: 'gray' }
  )

  processedRanges.sort(
    (a, b) => (a.max || Number.MAX_VALUE) - (b.max || Number.MAX_VALUE)
  )

  const isTextualLevel = typeof value === 'string'

  const getHexagonPositionByRangeName = (ranges) => {
    const rangeWidthPercent = 100 / ranges.length
    let hexagonPosition = 50

    processedRanges.forEach((range, index) => {
      const midPosition = (index + 0.5) * rangeWidthPercent
      if (
        range.name &&
        level &&
        range.name.toLowerCase() === level.toLowerCase()
      ) {
        hexagonPosition = midPosition
      }
    })

    return hexagonPosition
  }

  function getUnitBySection(sectionName, metric) {
    if (!metric.biomarkerDetail) return ''

    switch (sectionName.toLowerCase()) {
      case 'aerobic fitness':
        return (
          (metric.biomarkerDetail.cardiorespiratory &&
            metric.biomarkerDetail.cardiorespiratory.unit) ||
          ''
        )
      case 'body composition':
      case 'bone health':
        return (
          (metric.biomarkerDetail.body_comp &&
            metric.biomarkerDetail.body_comp.unit) ||
          ''
        )
      case 'muscle fitness':
      case 'balance':
      case 'movement quality':
        return (
          (metric.biomarkerDetail.physical_assessment &&
            metric.biomarkerDetail.physical_assessment.unit) ||
          ''
        )
      case 'cognitive health':
        return (
          (metric.biomarkerDetail.cognitive_health &&
            metric.biomarkerDetail.cognitive_health.unit) ||
          ''
        )
      default:
        return metric.biomarkerDetail.unit
    }
  }

  const renderRangeArea = (range, isTextualLevel) => {
    const colorObj = detailColors.find((item) => item.key === range.color) || {
      value: '#d2d2d2'
    }

    if (isTextualLevel) {
      return (
        <div className="range-info text-value">
          <span className="name" style={{ color: colorObj.value }}>
            {range.name}
          </span>
        </div>
      )
    }

    let area = ''
    let areaStyle = {}

    if (range.min && range.max) {
      area = `${range.min} - ${range.max}`
    } else if (range.min && !range.max) {
      area = `> ${range.min}`
    } else if (!range.min && range.max) {
      area = `< ${range.max}`
    }

    areaStyle = { color: colorObj && colorObj.value }

    return (
      <div className="range-info">
        <span className="area" style={areaStyle}>
          {area}
        </span>
        <span className="name">{range.name.toLowerCase()}</span>
      </div>
    )
  }

  const hexagonPosition = getHexagonPositionByRangeName(processedRanges)
  const colorObj = detailColors.find((item) => item.key === metric.color) || {
    value: '#d2d2d2'
  }

  return (
    <>
      {!isTextualLevel ? (
        <div className="ranges-info">
          <div
            className={`ranges-area-container ${rangeAreaStyle} range-area-wrap`}
          >
            <div className="range-title">
              <span>{type}</span>
              <span>Your Value</span>
            </div>
            <div className="range-level">
              <span>Your levels are {level}</span>
              <span style={{ color: colorObj.value }}>
                {value} {getUnitBySection(sectionName, metric)}
              </span>
            </div>
            <div className={'ranges-container ranges-container-wrap'}>
              {processedRanges.map((range, index) => {
                const rangeColorObj = detailColors.find(
                  (item) => item.key === range.color
                ) || { value: '#d2d2d2' }
                return (
                  <div
                    className="range-area"
                    key={index}
                    style={{
                      width: `${100 / processedRanges.length}%`,
                      position: 'relative',
                      display: 'inline-block',
                      height: '100%'
                    }}
                  >
                    <div className="progress-container progress-container-wrap">
                      <div
                        className="progress-bar"
                        style={{
                          backgroundColor: rangeColorObj.value
                        }}
                      />
                    </div>
                  </div>
                )
              })}

              <div
                className="hexagon-container hexagon-wrap"
                style={{
                  left: `${hexagonPosition}%`
                }}
              >
                <HexagonText
                  text={value ? value.toString() : '--'}
                  color={colorObj.value}
                  size={60}
                />
              </div>
            </div>

            <div className="range-info-container range-info-wrap">
              {processedRanges.map((range, index) => (
                <div
                  className="range-area"
                  key={index}
                  style={{ width: `${100 / processedRanges.length}%` }}
                >
                  {renderRangeArea(range)}
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="ranges-info">
          <div
            className={`ranges-area-container ${rangeAreaStyle} range-area-wrap`}
          >
            <div className="range-title">
              <span>{type}</span>
              <span>Your Value</span>
            </div>
            <div className="range-level">
              <span>{level}</span>
              <span style={{ color: colorObj.value }}>{value}</span>
            </div>
            <div className="range-info-container range-info-wrap textual-wrap">
                {processedRanges.map((range, index) => (
                <div
                  className="range-area"
                  key={index}
                  style={{ width: `${100 / processedRanges.length}%` }}
                >
                  {renderRangeArea(range, isTextualLevel)}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

function LowLevelMetrics({
  metricValues,
  view,
  hideSection,
  hideSectionHandle
}) {
  const groupedMetrics = useMemo(() => _.groupBy(metricValues, 'sectionName'), [
    metricValues
  ])
  // eslint-disable-next-line no-restricted-globals
  const url = location.pathname.split('/')
  const isPublished = url.includes('preview')
  const [reportId, setReportId] = useState(url[url.length - 1])
  const [lifeSuggestion, setLifeSuggestion] = useState('')
  const [allLifeSuggestions, setAllLifeSuggestions] = useState([])
  const [loading, setLoading] = useState(false)
  const [editIndex, setEditIndex] = useState([-1, -1])

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (allLifeSuggestions.length === 0) {
        const lifeSuggestions = await getLifeSuggestionsById(reportId)
        setAllLifeSuggestions(lifeSuggestions)
      }
    }
    fetchSuggestions()
  }, [])

  const getLifestyleSuggestion = (level, biomarkerDetail) => {
    if (!level || !biomarkerDetail)
      return 'No lifestyle suggestions available.'

    if (biomarkerDetail.body_comp) {
      const bodyCompRanges = biomarkerDetail.body_comp.ranges || []

      const bodyCompMatch = bodyCompRanges.find(
        (range) =>
          range.name && range.name.toLowerCase() === level.toLowerCase()
      )

      const custom_life_suggestions = allLifeSuggestions.filter(
        (item) =>
          item.parent_id === biomarkerDetail.body_comp.id &&
          item.type === 'body_comp'
      )
      if (custom_life_suggestions[0]) {
        return custom_life_suggestions[0].life_suggestion
      }

      if (bodyCompMatch && bodyCompMatch.life_suggestion) {
        return bodyCompMatch.life_suggestion
      }
    }

    if (biomarkerDetail.cognitive_health) {
      const bodyCompRanges = biomarkerDetail.cognitive_health.ranges || []

      const bodyCompMatch = bodyCompRanges.find(
        (range) =>
          range.name && range.name.toLowerCase() === level.toLowerCase()
      )

      const custom_life_suggestions = allLifeSuggestions.filter(
        (item) =>
          item.parent_id === biomarkerDetail.cognitive_health.id &&
          item.type === 'cognitive_health'
      )
      if (custom_life_suggestions[0]) {
        return custom_life_suggestions[0].life_suggestion
      }

      if (bodyCompMatch && bodyCompMatch.life_suggestion) {
        return bodyCompMatch.life_suggestion
      }
    }
    if (biomarkerDetail.physical_assessment) {
      const bodyCompRanges = biomarkerDetail.physical_assessment.ranges || []

      const bodyCompMatch = bodyCompRanges.find(
        (range) =>
          range.name && range.name.toLowerCase() === level.toLowerCase()
      )

      const custom_life_suggestions = allLifeSuggestions.filter(
        (item) =>
          item.parent_id === biomarkerDetail.physical_assessment.id &&
          item.type === 'physical_assessment'
      )
      if (custom_life_suggestions[0]) {
        return custom_life_suggestions[0].life_suggestion
      }

      if (bodyCompMatch && bodyCompMatch.life_suggestion) {
        return bodyCompMatch.life_suggestion
      }
    }
    if (biomarkerDetail.cardiorespiratory) {
      const bodyCompRanges = biomarkerDetail.cardiorespiratory.ranges || []

      const bodyCompMatch = bodyCompRanges.find(
        (range) =>
          range.name && range.name.toLowerCase() === level.toLowerCase()
      )

      const custom_life_suggestions = allLifeSuggestions.filter(
        (item) =>
          item.parent_id === biomarkerDetail.cardiorespiratory.id &&
          item.type === 'cardiorespiratory'
      )
      if (custom_life_suggestions[0]) {
        return custom_life_suggestions[0].life_suggestion
      }

      if (bodyCompMatch && bodyCompMatch.life_suggestion) {
        return bodyCompMatch.life_suggestion
      }
    }

    if (biomarkerDetail.conventional_ranges) {
      const conventionalMatch = biomarkerDetail.conventional_ranges.find(
        (range) =>
          range.name && range.name.toLowerCase() === level.toLowerCase()
      )

      const custom_life_suggestions = allLifeSuggestions.filter(
        (item) =>
          item.parent_id === biomarkerDetail.id &&
          item.type === 'bio_marker.conventional'
      )
      if (custom_life_suggestions[0]) {
        return custom_life_suggestions[0].life_suggestion
      }

      if (conventionalMatch && conventionalMatch.life_suggestion) {
        return conventionalMatch.life_suggestion
      }
    }

    return 'No lifestyle suggestions available.'
  }

  const handleLifeSuggestionsSubmit = async (biomarkerDetail) => {
    if (!lifeSuggestion.trim()) {
      message.warning('Lifestyle suggestions should not be empty')
      return
    }
    setLoading(true)
    let data = {
      assessment_review_id: reportId,
      life_suggestion: lifeSuggestion
    }

    const person = JSON.parse(localStorage.getItem('phs-user'))

    if (biomarkerDetail.body_comp) {
      const custom_life_suggestions = allLifeSuggestions.filter(
        (item) =>
          item.parent_id === biomarkerDetail.body_comp.id &&
          item.type === 'body_comp'
      )
      if (custom_life_suggestions[0]) {
        data = {
          ...custom_life_suggestions[0],
          ...data,
          updated_id: person.id,
          type: 'body_comp'
        }
      } else {
        data = {
          ...data,
          parent_id: biomarkerDetail.body_comp.id,
          created_id: person.id,
          type: 'body_comp'
        }
      }
    }

    if (biomarkerDetail.cognitive_health) {
      const custom_life_suggestions = allLifeSuggestions.filter(
        (item) =>
          item.parent_id === biomarkerDetail.cognitive_health.id &&
          item.type === 'cognitive_health'
      )
      if (custom_life_suggestions[0]) {
        data = {
          ...custom_life_suggestions[0],
          ...data,
          updated_id: person.id,
          type: 'cognitive_health'
        }
      } else {
        data = {
          ...data,
          parent_id: biomarkerDetail.cognitive_health.id,
          created_id: person.id,
          type: 'cognitive_health'
        }
      }
    }
    if (biomarkerDetail.physical_assessment) {
      const custom_life_suggestions = allLifeSuggestions.filter(
        (item) =>
          item.parent_id === biomarkerDetail.physical_assessment.id &&
          item.type === 'physical_assessment'
      )
      if (custom_life_suggestions[0]) {
        data = {
          ...custom_life_suggestions[0],
          ...data,
          updated_id: person.id,
          type: 'physical_assessment'
        }
      } else {
        data = {
          ...data,
          parent_id: biomarkerDetail.physical_assessment.id,
          created_id: person.id,
          type: 'physical_assessment'
        }
      }
    }

    if (biomarkerDetail.cardiorespiratory) {
      const custom_life_suggestions = allLifeSuggestions.filter(
        (item) =>
          item.parent_id === biomarkerDetail.cardiorespiratory.id &&
          item.type === 'cardiorespiratory'
      )
      if (custom_life_suggestions[0]) {
        data = {
          ...custom_life_suggestions[0],
          ...data,
          updated_id: person.id,
          type: 'cardiorespiratory'
        }
      } else {
        data = {
          ...data,
          parent_id: biomarkerDetail.cardiorespiratory.id,
          created_id: person.id,
          type: 'cardiorespiratory'
        }
      }
    }

    if (biomarkerDetail.conventional_ranges) {
      const custom_life_suggestions = allLifeSuggestions.filter(
        (item) =>
          item.parent_id === biomarkerDetail.id &&
          item.type === 'bio_marker.conventional'
      )
      if (custom_life_suggestions[0]) {
        data = {
          ...custom_life_suggestions[0],
          ...data,
          updated_id: person.id,
          type: 'bio_marker.conventional'
        }
      } else {
        data = {
          ...data,
          parent_id: biomarkerDetail.id,
          created_id: person.id,
          type: 'bio_marker.conventional'
        }
      }
    }
    let newSuggestionId = 0
    if (data.updated_id) {
      await updateLifeSuggestions(data)
    } else {
      const { id } = await createLifeSuggestions(data)
      newSuggestionId = id
    }
    setLoading(false)
    setEditIndex([-1, -1])
    let allLifeSuggestionsCopy = [...allLifeSuggestions]
    const exists = allLifeSuggestionsCopy.filter(
      (item) => item.parent_id === data.parent_id && item.type === data.type
    )
    if (exists[0]) {
      allLifeSuggestionsCopy = allLifeSuggestionsCopy.map((item) => {
        if (item.parent_id === data.parent_id) {
          return {
            ...item,
            life_suggestion: lifeSuggestion
          }
        }
        return item
      })
    } else {
      allLifeSuggestionsCopy.push({
        ...data,
        id: newSuggestionId
      })
    }
    setAllLifeSuggestions(allLifeSuggestionsCopy)
  }

  return (
    <div className="grouped-container">
      {Object.keys(groupedMetrics).map((sectionName, id) => {
        const sectionMetrics = groupedMetrics[sectionName]

        const hasSubmetrics = sectionMetrics.some(
          (metric) => metric.submetrics
        )

        return (
          <div key={sectionName} className="metric-section-container">
            {!hasSubmetrics ? (
              sectionMetrics.map((metric, index) => {
                const initialSuggestion =
                  getLifestyleSuggestion(
                    metric.level,
                    metric.biomarkerDetail
                  ) || ''
                return (
                  <ConditionalHideWrap
                    key={sectionName}
                    name={metric.displayName}
                    title={metric.displayName}
                    syncStatus={hideSectionHandle}
                    status={hideSection}
                    view={view}
                    isLowlevelMetrics={true}
                    condition={
                      view === 'Draft' ||
                      (view !== 'Draft' &&
                        !hideSection.includes(`${metric.displayName}`))
                    }
                  >
                    <React.Fragment key={index}>
                      <div className="display-section">
                        <h4 className="display-name">{metric.displayName}</h4>
                      </div>
                      <div
                        className="metric-container"
                        style={{
                          display: 'flex',
                          gap: '20px',
                          marginBottom: '20px'
                        }}
                      >
                        <div className="ranges-info bar-wrap">
                          <MetricRangesArea
                            type="CONVENTIONAL"
                            value={metric.value}
                            ranges={metric.conventionalRanges || []}
                            color={metric.color}
                            level={metric.level}
                            metric={metric}
                            sectionName={sectionName}
                          />
                        </div>
                        <div className="tabs tab-div">
                          <Tabs
                            defaultActiveKey="2"
                            centered
                            className="tab-background"
                            tabBarGutter={40}
                            tabBarStyle={{ borderBottom: '1px solid #e8e8e8' }}
                          >
                            <TabPane
                              tab={
                                <div className="tab-style">
                                  <Lifestyle color="#264382" />
                                  <span>Lifestyle Suggestions</span>
                                </div>
                              }
                              key="2"
                              className=""
                            >
                              {editIndex[0] === id &&
                              editIndex[1] === index &&
                              !isPublished ? (
                                <>
                                  <div className="tab-scroll">
                                    <BasicForm
                                      formData={[
                                        {
                                          type: 'richText',
                                          label: '',
                                          dbField: 'life_suggestion'
                                        }
                                      ]}
                                      valueForm={{
                                        life_suggestion: initialSuggestion
                                      }}
                                      onChange={(values) => {
                                        setLifeSuggestion(
                                          values.life_suggestion.value
                                        )
                                      }}
                                    />
                                  </div>
                                  <div className="action-btns">
                                    <div
                                      className="cancel"
                                      onClick={() =>
                                        handleLifeSuggestionsSubmit(
                                          metric.biomarkerDetail
                                        )
                                      }
                                    >
                                      {loading && <LoadingOutlined />}
                                      SAVE
                                    </div>
                                    <div
                                      className="cancel"
                                      onClick={() => setEditIndex([-1, -1])}
                                    >
                                      CANCEL
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div
                                    className="tab-content tab-scroll"
                                    dangerouslySetInnerHTML={{
                                      __html: initialSuggestion || 'No content'
                                    }}
                                    onClick={() => {
                                      setLifeSuggestion(initialSuggestion)
                                      setEditIndex([id, index])
                                    }}
                                  />
                                </>
                              )}
                            </TabPane>
                            <TabPane
                              tab={
                                <div className="tab-style">
                                  <About color="#264382" />
                                  <span>About</span>
                                </div>
                              }
                              key="1"
                              className="tab-scroll"
                            >
                              <div
                                className="tab-content"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    metric.biomarkerDetail.introduction ||
                                    metric.introduction
                                }}
                              />
                            </TabPane>
                          </Tabs>
                        </div>
                      </div>
                    </React.Fragment>
                    <Divider />
                  </ConditionalHideWrap>
                )
              })
            ) : (
              <>
                <div className="display-section">
                  <h2 className="section-name">{sectionName}</h2>
                </div>
                <Divider />
                {sectionMetrics.map((metric, index) => {
                  const initialSuggestion =
                    getLifestyleSuggestion(
                      metric.level,
                      metric.biomarkerDetail
                    ) || ''
                  return (
                    <ConditionalHideWrap
                      key={sectionName}
                      name={metric.displayName}
                      title={metric.displayName}
                      syncStatus={hideSectionHandle}
                      status={hideSection}
                      view={view}
                      isLowlevelMetrics={true}
                      isSection={false}
                      condition={
                        view === 'Draft' ||
                        (view !== 'Draft' &&
                          !hideSection.includes(`${metric.displayName}`))
                      }
                    >
                      <React.Fragment key={index}>
                        <h4 className="display-name">{metric.displayName}</h4>
                        <div
                          className="metric-container"
                          style={{
                            display: 'flex',
                            gap: '20px',
                            marginBottom: '20px'
                          }}
                        >
                          <div className="ranges-info bar-wrap">
                            <MetricRangesArea
                              type="CONVENTIONAL"
                              value={metric.value}
                              ranges={metric.conventionalRanges || []}
                              color={metric.color}
                              level={metric.level}
                              metric={metric}
                              sectionName={sectionName}
                            />
                          </div>
                          <div className="tabs tab-div">
                            <Tabs
                              defaultActiveKey="1"
                              centered
                              className="tab-background"
                              tabBarGutter={40}
                              tabBarStyle={{
                                borderBottom: '1px solid #e8e8e8'
                              }}
                            >
                              <TabPane
                                tab={
                                  <div className="tab-style">
                                    <About color="#264382" />
                                    <span>About</span>
                                  </div>
                                }
                                key="1"
                                className="tab-scroll"
                              >
                                <div
                                  className="tab-content"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      metric.biomarkerDetail.introduction ||
                                      metric.introduction
                                  }}
                                />
                              </TabPane>
                              <TabPane
                                tab={
                                  <div className="tab-style">
                                    <Lifestyle color="#264382" />
                                    <span>Lifestyle Suggestions</span>
                                  </div>
                                }
                                key="2"
                                className=""
                              >
                                {editIndex[0] === id &&
                                editIndex[1] === index &&
                                !isPublished ? (
                                  <>
                                    <div className="tab-scroll">
                                      <BasicForm
                                        formData={[
                                          {
                                            type: 'richText',
                                            label: '',
                                            dbField: 'life_suggestion'
                                          }
                                        ]}
                                        initialValues={{
                                          life_suggestion: initialSuggestion
                                        }}
                                        onChange={(values) => {
                                          setLifeSuggestion(
                                            values.life_suggestion.value
                                          )
                                        }}
                                      />
                                    </div>
                                    <div className="action-btns">
                                      <div
                                        className="cancel"
                                        onClick={() =>
                                          handleLifeSuggestionsSubmit(
                                            metric.biomarkerDetail
                                          )
                                        }
                                      >
                                        {loading && <LoadingOutlined />}
                                        SAVE
                                      </div>
                                      <div
                                        className="cancel"
                                        onClick={() => setEditIndex([-1, -1])}
                                      >
                                        CANCEL
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      className="tab-content tab-scroll"
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          initialSuggestion || 'No content'
                                      }}
                                      onClick={() => {
                                        setLifeSuggestion(initialSuggestion)
                                        setEditIndex([id, index])
                                      }}
                                    />
                                  </>
                                )}
                              </TabPane>
                            </Tabs>
                          </div>
                        </div>
                      </React.Fragment>
                    </ConditionalHideWrap>
                  )
                })}
              </>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default LowLevelMetrics
